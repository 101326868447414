@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@800&display=swap");

.DangerousHtmlText {
  font-family: Archivo, sans-serif;
  font-weight: 800;
  /* font-style: italic; */
}

.intrested_btn {
  margin-left: 3rem;
  color: white
}

.intrested_btn_icon {
  color: white;
  padding-right: 0.3rem;

}




.store_landing_banner {
  display: flex;
  /* justify-content: space-around; */
  color: black;
  /* margin-bottom: 50px; */
  /* border: 1px solid black; */
  height: 40vw;
  margin-bottom: 30px;
}

.store_landing_banner-mob {
  display: flex;
  flex-direction: column;
  color: black;
  margin-bottom: 30px;
}

.store_landing_banner-left-mob {
  width: 100%;
  /* border: 1px solid purple; */
  padding: 25px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.store_landing_banner-left {
  width: 50%;
  /* border: 1px solid purple; */
  padding: 25px;
  /* padding-right: 10rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.store_landing_banner-text h1 {
  font-size: 3rem;
  font-weight: bold;
  font-family: Archivo, sans-serif;
  font-weight: 800;
  word-spacing: 0.4rem;
  color: #353935;
}

.store_landing_banner-text ul li {
  color: #4d4d4d;
  font-size: 1.6rem;
}

.store_landing_banner-text ul {
  padding-left: 4rem;

}



.store_landing_banner-image1-mob {
  position: relative;
  /* width: 100%; */
  /* padding: 20px; */
  left: 10vw;
  height: 70%;
  object-fit: scale-down;
  /* border: 1px solid black; */
}

.store_landing_banner-image2-mob {
  position: absolute;
  top: 10vh;
  left: 5vw;
  z-index: 10;
  height: 450px;
  object-fit: scale-down;
}

.store_landing_banner-right-mob {
  position: relative;
  overflow: hidden;
  height: 450px;
  /* border: 2px solid red; */
}

.store_landing_banner-image1-mob img {
  height: 100%;
}

.store_landing_banner-image2-mob img {
  height: 100%;
}

.store_landing_banner-right {
  width: 50%;
  position: relative;
  /* border: 2px solid red; */
}

.store_landing_banner-image1 {
  padding: 20px;
  position: absolute;
  z-index: 0;
  width: 100%;
  /* height: 40vh; */
  margin-top: 2vh;
  left: 40px;
  /* object-fit: contain; */
}

.store_landing_banner-image1 img {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  height: 100%;
}

.store_landing_banner-image2 {
  /* padding: 50px; */
  position: absolute;
  z-index: 10;
  left: 10px;
  top: 8vw;
  height: 32vw;
  /* width: 50%; */
  /* border: 2px solid blue; */
  object-fit: scale-down;
}

.store_landing_banner-image2 img {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);
  height: 100%;
}

@media screen and (max-width: 1100px) {
  .store_landing_banner-text p {
    font-size: 0.8rem;
  }

  .store_landing_banner-text h1 {
    font-size: 2.2rem;
  }

  /* .store_landing_banner-text {
    padding-left: 40px;
    padding-top: 40px;
  } */
  /* .store_landing_banner-image2 {
    top: 10px;
    right: 50px;
    height: 60%;
    width: 60%;
  } */
}

@media screen and (max-width: 600px) {
  .store_landing_banner-text ul li {
    color: #4d4d4d;
    font-size: 1.2rem;
  }

  .store_landing_banner-text ul {
    padding-left: 1rem;

  }

  .intrested_btn {
    margin-left: 0rem;
    color: white
  }


}

@media screen and (max-width: 700px) {
  .store_landing_banner-text p {
    font-size: 1.25rem;
  }
}