.pricing_container {
    background-color: whitesmoke;
    padding: 2rem;
    text-align: center;


}

.pricing_container h1 {
    font-size: 3rem;
    font-weight: bold;
    color: #353935;
}


.Header_row_business_listing {
    display: flex;
    justify-content: center;
    text-align: center;

}

.pricing_table_heading {
    margin-bottom: 2px;
    background-color: #b7dedd;
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    color: #353935;
    padding: 1rem;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.pricing_table_heading p {
    font-size: 1rem;
    color: #353935;
    width: 100%;

}

.pricing_table_heading a {
    font-weight: 100;
    font-size: 0.8rem;
    color: white;
    border-radius: 15px;
    background-color: #0e918c;
    padding: 0.5rem 0.7rem;
    width: 70px;
}


.pricing_table_ticks {

    background-color: #b7dedd;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0px;
    margin-bottom: 1px;

}

.pricing_table_elem {
    background-color: #353935;
    color: white;
    padding: 1rem;
    line-height: 25px;
    margin-bottom: 1px;
}

.green_icon {
    color: rgb(17, 182, 17);
}





@media screen and (max-width: 600px) {
    .pricing_table_heading {
        margin-left: 0px;
        font-size: 1.2rem;

    }

    .pricing_table_heading p {
        font-size: 0.7rem;
    }

    .pricing_table_ticks {
        margin-left: 0px;
    }

    .pricing_table_elem {

        padding: 0.5rem;
        font-size: 0.8rem;

    }

    .pricing_container h1 {
        font-size: 2rem;

        color: #353935;
    }
}