.subheader {
    font-weight: 600;
    color: var(--ion-color-primary-dark);
    text-align: center;
    padding: 30px;
    box-shadow: -7px -5px 5px 0px rgba(var(--ion-color-medium-rgb),0.75);
    /* position: sticky;
    top: 0;
    z-index: 10; */
    background: linear-gradient(to bottom, var(--ion-color-primary-light), var(--ion-color-light));
}
.subheader-sticky-scroll {
    position: sticky;
    /* top: 0; */
    z-index: 10;
}
.subheader-font-pc {
    font-size: 3vw;
}
.subheader-font-tab {
    font-size: 4.5vw;
}
.subheader-font-mob {
    font-size: 6vw;
}