:root {
  --ion-color-secondary-dark: #831804;
  --ion-color-secondary-light: #dd9182;
  --ion-color-secondary-light-rgb: 221, 145, 130;
  --ion-color-primary-dark: #074946;
  --ion-color-primary-light: #b7dedd;
  --ion-color-primary-light-rgb: 183, 222, 221;
  --ion-color-primary-lighter: #f1f8f8;
  --ion-color-tertiary-dark: #006084;
  --ion-color-tertiary-light: #80d0ee;
  --ion-color-tertiary-light-rgb: 128, 208, 238;
  --ion-color-primary-header: #56b2af;
  --ion-color-danger-background: #ffdadb;
  --ion-color-primary-d1: #0b7470;
  --ion-color-primary-d2: #0a6662;
  --ion-color-primary-d3: #085754;
  --ion-color-primary-d4: #074946;
  --ion-color-primary-d5: #063a38;
  --ion-color-primary-d6: #042b2a;
  --ion-color-primary-l1: #3ea7a3;
  --ion-color-primary-l2: #56b2af;
  --ion-color-primary-l3: #6ebdba;
  --ion-color-primary-l4: #87c8c6;
  --ion-color-primary-l5: #9fd3d1;
  --ion-color-primary-l6: #b7dedd;
  --ion-color-dark: black;
  --ion-color-yellow-rgb: 255, 253, 208;
  --ion-color-blue-rgb: 218, 240, 255;
  --ion-color-red-rgb: 246,223,223;
  --ion-color-sky: #87CEEB;
  --ion-color-grey-bg: #cdcdcd;

  /* --ion-toolbar-background: linear-gradient(to bottom, var(--ion-color-primary-lighter), var(--ion-color-primary-light)); */
  --ion-toolbar-background: var(--ion-color-light);
  --ion-toolbar-color: var(--ion-color-primary-dark);
  background-color: var(--ion-color-light);


  --ion-color-banner-blue: #21d0dd;
  --ion-color-banner-red: #dd2121;
  --ion-color-banner-red-rgb: 221, 33, 33;
  --ion-color-banner-blue-rgb: 33, 208, 221;
  --ion-color-banner-navy-blue: #0d8dd9;
  --ion-color-primary-blue: #2a6ef2;
  --ion-color-primary-blue-light: #caddff;
  /* --ion-color-banner-blue: #b7dedd;
  --ion-color-banner-navy-blue: #0b7470; */
}