.idigizen-solution-banner-wrpr {
  box-shadow: 0px 9px 5px 0px rgba(var(--ion-color-medium-rgb), 0.75);
  position: relative;
  /* margin-bottom: 2em; */
}
.idigizen-solution-cards {
  padding: 1.5em;
  padding-top: 0;
}
.idigizen-solution-content {
  --background: url("../../../public/assets/images/background.png");
}
.idigizen-solution-top {
  width: 100%;
  height: calc(65vw / 3);
  position: relative;
  background: linear-gradient(
    to right,
    var(--ion-color-banner-blue),
    var(--ion-color-banner-navy-blue)
  );
}
.idigizen-solution-top-mob {
  width: 100%;
  height: 50vh;
  position: relative;
  background: linear-gradient(
    to right,
    var(--ion-color-banner-blue),
    var(--ion-color-banner-navy-blue)
  );
}

/* .idigizen-solution-top:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw 400px 0;
  border-color: transparent #FFC107 transparent transparent;
  background-image: var(to right, red, green);
} */

.idigizen-solution-banner-border {
  background: transparent;
  height: calc(100vw / 11);
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: 100;
}
.idigizen-solution-banner-border:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100vw calc(100vw / 11) 0;
  border-color: transparent transparent var(--ion-color-light) transparent;
}
.idigizen-solution-banner-overlaycard {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 200;
  /* border: 5px solid black; */
  overflow: hidden;
}
.idigizen-solution-banner-img {
  width: 60%;
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: scale-down;
}
.idigizen-solution-banner-img-mob {
  width: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  object-fit: scale-down;
}
.idigizen-solution-banner-header-banner-mob {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  /* border: 2px solid green; */
  padding-left: 1em;
  font-family: "Source Sans Pro", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.idigizen-solution-banner-subheader-mob {
  font-size: 1.5em;
  color: var(--ion-color-light);
  font-weight: 300;
}
.idigizen-solution-banner-header-mob {
  font-size: 2em;
  color: var(--ion-color-light);
  font-weight: 700;
}
.idigizen-solution-banner-header-banner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  /* border: 2px solid green; */
  font-family: "Source Sans Pro", sans-serif;
  padding-left: 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
}
.idigizen-solution-banner-subheader-tab {
  font-size: 2em;
  color: var(--ion-color-light);
  font-weight: 300;
}
.idigizen-solution-banner-header-tab {
  font-size: 2.5em;
  color: var(--ion-color-light);
  font-weight: 700;
}
.idigizen-solution-banner-subheader {
  font-size: 3em;
  color: var(--ion-color-light);
  font-weight: 300;
}
.idigizen-solution-banner-header {
  font-size: 3.5em;
  color: var(--ion-color-light);
  font-weight: 700;
}
.idigizen-solution-banner-button {
  font-family: "Tilt Warp", cursive;
  background-color: var(--ion-color-tertiary);
  background-color: var(--ion-color-secondary);
  /* color: var(--ion-color-light); */
  color: white;
  font-size: 1.1em;
  /* font-weight: bold; */
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  border: transparent;
  /* border: 2px solid var(--ion-color-banner-navy-blue); */
  border-radius: 20px;
  margin-top: 4rem;
}

.idigizen-solution-banner-button:hover {
  cursor: pointer;
  background: var(--ion-color-banner-blue);
  color: var(--ion-color-light);
  border: 0.5px solid var(--ion-color-light);
  box-sizing: border-box;
}
.idigizen-solution-banner-wfp-mob {
  width: 100%;
  font-family: "Water Brush", cursive;
  font-size: 2.5em;
  text-align: center;
  margin-top: 1em;
}
.idigizen-solution-banner-wfp {
  position: absolute;
  right: 0;
  top: 0;
  height: 75%;
  width: 50%;
  /* border: 5px solid maroon; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.idigizen-solution-banner-wfp-text {
  font-family: "Water Brush", cursive;
  font-size: 4em;
  margin-bottom: 5rem;

  text-align: center;
}
.idigizen-solution-banner-wfp-text-tab {
  font-family: "Water Brush", cursive;
  font-size: 3em;
  text-align: center;
  margin-bottom: 4rem;
}
.idigizen-solution-banner-invisible {
  opacity: 0;
  transition: opacity 0.5s ease;
}
.idigizen-solution-banner-visible {
  opacity: 1;
  transition: opacity 0.5s ease;
}
.idigizen-solution-card {
  border: 2px solid var(--ion-color-banner-navy-blue);
  border-radius: 15px;
  background: rgba(var(--ion-color-light-rgb), 0.75);
  padding: 0.5em;
}
.idigizen-solution-card-full-height {
  height: 100%;
}
.idigizen-solution-card-pc {
  padding-right: 1em;
}
.idigizen-solution-card-mob {
  padding-right: 1em;
}
.idigizen-solution-card-img {
  width: 40%;
}
.idigizen-solution-card-text-mob {
  font-size: medium;
  font-weight: bold;
}
.idigizen-solution-card-text {
  font-size: large;
  font-weight: bold;
}
.idigizen-solution-band-mob {
  padding: 1em;
}
.idigizen-solution-band {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.idigizen-solution-no-band {
  background-color: rgba(var(--ion-color-light-rgb), 0.15);
}
.idigizen-solution-blue-band {
  background-color: rgba(var(--ion-color-banner-blue-rgb), 0.15);
}
.idigizen-solution-img-left-band {
  padding-left: 5em;
}
.idigizen-solution-img-right-band {
  padding-right: 5em;
  flex-direction: row-reverse;
}
.idigizen-solution-pie-img-mob {
  width: 100%;
}
.idigizen-solution-pie-img {
  width: 30%;
}
.idigizen-solution-pie-img-tab {
  width: 30%;
}
.idigizen-solution-pie-hdr-mob {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  padding-left: 0.5em;
}
.idigizen-solution-pie-hdr {
  font-size: 1.3em;
  font-weight: bold;
  margin-bottom: 1em;
}
.idigizen-solution-pie-hdr-img-left-mob {
  border-left: 10px solid var(--ion-color-secondary);
}
.idigizen-solution-pie-hdr-img-right-mob {
  border-left: 10px solid var(--ion-color-tertiary);
}
.idigizen-solution-pie-hdr-img-left {
  text-align: right;
  border-right: 10px solid var(--ion-color-secondary);
  padding-right: 0.5em;
}
.idigizen-solution-pie-hdr-img-right {
  text-align: left;
  border-left: 10px solid var(--ion-color-tertiary);
  padding-left: 0.5em;
}
.idigizen-solution-pie-text {
  width: 60%;
  font-size: 1.2em;
}
.idigizen-solution-pie-text-mob {
  font-size: 1.2em;
}
.idigizen-solution-pie-text-img-left {
  /* padding-right: 4em; */
  text-align: right;
}
.idigizen-solution-pie-text-img-right {
  /* padding-left: 4em; */
  text-align: left;
}
.idigizen-solution-flash-card-button {
  border: 2px solid var(--ion-color-banner-blue);
}
.idigizen-solution-app-banner-top-mob {
  box-shadow: 0px -9px 5px 0px rgba(var(--ion-color-medium-rgb), 0.75);
  background: linear-gradient(
    to bottom,
    var(--ion-color-banner-blue),
    var(--ion-color-banner-navy-blue)
  );
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.idigizen-solution-app-banner-top {
  height: 70vh;
  box-shadow: 0px -9px 5px 0px rgba(var(--ion-color-medium-rgb), 0.75);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.idigizen-solution-app-banner-img {
  height: 100%;
}
.idigizen-solution-app-banner-img-section {
  padding: 2em;
  height: 100%;
  width: 50%;
  display: flex;
  align-items: center;
  padding-left: 2em;
  background: linear-gradient(
    to right,
    var(--ion-color-banner-blue),
    var(--ion-color-banner-navy-blue)
  );
}
.idigizen-solution-app-banner-header-mob {
  font-size: 1.5em;
  color: var(--ion-color-light);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.idigizen-solution-app-banner-img-mob {
  height: 50vh;
}

.idigizen-solution-app-banner-header {
  font-size: 3em;
  color: var(--ion-color-light);
  font-family: "Source Sans Pro", sans-serif;
  font-weight: bold;
}
.idigizen-solution-app-banner-text {
  background: var(--ion-color-banner-navy-blue);
  font-family: "Source Sans Pro", sans-serif;
  flex-grow: 1;
  width: 50%;
  font-size: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding-top: 2em;
  padding-bottom: 2em;
  padding-right: 2em;
  color: var(--ion-color-light);
}
.idigizen-solution-app-banner-text-mob {
  font-size: 1em;
  color: var(--ion-color-light);
}
.idigizen-solution-app-banner-text-bullet-mob {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}
.idigizen-solution-app-banner-text-bullet {
  margin: 1em;
}
.promo-popover-modal {
  --height: 90vh;
  --width: 30vw;
}
.promo-popover-modal-mob {
  --height: 80vh;
  --width: 95vw;
}
.izp-table {
  border: 1px solid var(--ion-color-medium);
  border-collapse: collapse;
  background: var(--ion-color-light);
}
.izp-header {
  font-weight: bold;
  background: var(--ion-color-primary-blue-light);
  color: var(--ion-color-banner-navy-blue);
}
.izp-header-mob {
  font-weight: bold;
  background: var(--ion-color-primary-blue-light);
  color: var(--ion-color-banner-navy-blue);
}

@media screen and (min-width: 700px) {
  .idigizen-solution-top {
    height: calc(80vw / 3);
  }
  .idigizen-solution-banner-button {
    /* border: 2px solid var(--ion-color-banner-navy-blue); */
    border-radius: 20px;
    margin-top: 1rem;
  }
}
@media screen and (min-width: 950px) {
  .idigizen-solution-top {
    height: calc(65vw / 3);
  }
  .idigizen-solution-banner-button {
    /* border: 2px solid var(--ion-color-banner-navy-blue); */
    border-radius: 20px;
    margin-top: 4rem;
  }
}
.idigizen-sol-pdf-viewer-modal {
  --height: 100%;
  --width: 100%;
}