.graphic_with_text-container {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.graphic_with_text-haeding {
  width: 100%;
  text-align: center;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.graphic_with_text-haeding h1 {
  width: 75%;
  font-size: 2.2rem;
  font-weight: bold;
  line-height: 40px;
}
.graphic_with_text-center {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 85%;
}

.graphic_with_text-left {
  width: 50%;
}
.graphic_with_text-right {
  width: 50%;
  padding: 40px;
}
.graphic_with_text-right ul li {
  line-height: 40px;
  font-size: 1.3rem;
}

.graphic_with_text-right h1 {
  font-size: 1.8rem;
  font-weight: bold;
}
.graphic_with_text-img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid black; */
}
.graphic_with_text-img-bg {
  background-color: aquamarine;
  height: 40vh;
  width: 55vh;
  border-radius: 12px;
  padding: 10px;
  object-fit: cover;
}
.graphic_with_text-img-bg img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 950px) {
  .graphic_with_text-center {
    width: 90%;
  }
  .graphic_with_text-img {
    padding: 20px;
  }
  .graphic_with_text-right {
    padding: 20px;
  }
  .graphic_with_text-haeding h1 {
    width: 82%;
  }
}
@media screen and (max-width: 700px) {
  .graphic_with_text-haeding h1 {
    width: 92%;
    font-size: 1.8rem;
  }
  .graphic_with_text-center {
    width: 95%;
  }
  .graphic_with_text-img {
    padding: 10px;
  }
  .graphic_with_text-right {
    padding: 10px;
  }
}

@media screen and (max-width: 600px) {
  .graphic_with_text-haeding h1 {
    width: 100%;
    font-size: 1.8rem;
  }
  .graphic_with_text-center {
    width: 100%;
    flex-direction: column;
  }
  .graphic_with_text-left {
    width: 100%;
  }
  .graphic_with_text-right {
    width: 100%;
    padding: 10px;
  }
  .graphic_with_text-right h1 {
    text-align: center;
    font-size: 1.4rem;
  }
  .graphic_with_text-img {
    padding: 10px;
  }
  .graphic_with_text-right ul li {
    line-height: 30px;
    font-size: 1rem;
  }
}
