/* @import url("https://fonts.googleapis.com/css2?family=Anton&display=swap"); */
.cardwithnum-container {
  background: rgba(var(--ion-color-light-rgb), 0.75);

  border: 2px solid var(--ion-color-banner-navy-blue);
}
.cardwithnum-main-container {
  border: 2px solid var(--ion-color-banner-navy-blue);
  display: flex;
  justify-content: space-around;
}
.cardwithnum-col {
  border-radius: 22px;
  background: rgba(var(--ion-color-light-rgb), 0.75);
  border: 2px solid var(--ion-color-banner-navy-blue);
  padding: 10px;
}
.cardwithnum-card {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* flex-wrap: wrap; */
}
.cardwithnum-card-number {
  /* padding: 0px 10px 0px 10px; */
  /* color: red; */
  padding: 10px;

}
.cardwithnum-card-number {
  font-size: 3rem;
  color: var(--ion-color-banner-navy-blue);
  font-weight: bold;
}
.cardwithnum-card-text {
  /* color: var(--ion-color-tertiary-dark); */
  font-weight: bold;
  font-size: 1.5rem;
}
.cardwithnum-card-number-tab {
  font-size: 2rem;
  color: var(--ion-color-banner-navy-blue);
  font-weight: bold;
  padding: 5px;
}
.cardwithnum-card-text-tab {
  /* color: var(--ion-color-tertiary-dark); */
  font-weight: bold;
  font-size: 1rem;
}
@media (max-width: 759px) {
  .cardwithnum-card {
    flex-direction: column;
  }
  .cardwithnum-card-number {
    font-size: 2rem;
  }
  .cardwithnum-card-text {
    font-size: 1rem;
  }
}
@media (max-width: 500px) {
  .cardwithnum-card-number {
    font-size: 2rem;
  }
  .cardwithnum-card-text {
    font-size: 0.8rem;
  }
}