.profile {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  display: inline-block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  border: 4px solid var(--ion-color-secondary);
}

.storelisting_backdrop {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.storelisting_container {
  width: 90%;
  /* padding: 20px; */
}

.row {
  border-bottom: 2px rgb(197, 191, 191) solid;
  padding: 20px;
}

.storelisting_card {
  display: flex;
  border-radius: 8px;
  padding: 35px;
  margin-bottom: 35px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-right: 5px;
  flex-direction: column;
  background-color: white;
  justify-content: space-between;

  border-bottom: 2px rgb(197, 191, 191) solid;
}
.top {
  /* height: 40%; */
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.top_head {
  width: 100%;
  display: flex;
  align-items: center;
}
.storelisting_card-heading-box {
  margin-left: 20px;
  line-height: 0px;
}
.top_body {
  width: 100%;
}
.top h3 {
  width: 350px;
  line-height: 20px;
  font-weight: bold;
  margin-bottom: 5px;
  text-transform: capitalize;
  color: var(--ion-color-tertiary);
}
.storelisting_card-heading-box p {
  color: rgb(47, 46, 46);
  font-size: 0.8rem;
}

.bottom {
  /* height: 60%; */
  width: 100%;
}
.bottom h5 {
  width: auto;
}

.listing_btn {
  /* position: absolute; */
  bottom: -5;
}
.listing_btn a {
  color: white;
}

@media screen and (max-width: 480px) {
  .storelisting_container {
    width: 97%;
    padding: 0px 2px;
    margin-left: 5px;
  }
}
