.header-logo {
  width: 200px;
}
.header-logo:hover {
  cursor: pointer;
}

.header-logo-mobile {
  width: 170px;
}

.header-logo-img {
  height: 100%;
  width: 100%;
}

.header-top {
  padding: 15px;
  padding-left: 30px;
  /* background: linear-gradient(to bottom , var(--ion-color-primary-light), #fefefe); */
  background: var(--ion-color-light);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 201;
  /* height: 90px; */
  box-shadow: 0px 9px 5px 0px rgba(var(--ion-color-medium-rgb), 0.75);
}

.header-toolbar-nav-link {
  color: var(--ion-color-dark);
  text-decoration: none;
  padding: 5px;
  margin: 5px;
}

.header-toolbar-nav-link:hover {
  border-bottom: 2px solid var(--ion-color-primary-dark);
}

.header-toolbar {
  display: flex;
  align-items: center;
}
.header-buttons {
  display: flex;
  margin-left: 20px;
}
.header-mobile-buttons {
  display: flex;
}
