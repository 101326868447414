@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

* {
  font-family: "Questrial", sans-serif;
  font-style: normal;
}
.navlink-class {
  position: absolute;
  top: -100px;
}
