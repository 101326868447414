.aboutus-top {
    position: relative;
    background: var(--ion-color-light);
    /* padding-top: 20px; */
}
.aboutus-wrapper-mob {
    /* height: 250px; */
    /* border: 2px solid black; */
    background-image: linear-gradient(
      to top, transparent, var(--ion-color-light) 
      ), url("../../../public/assets/images/background.png");
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.aboutus-wrapper {
    /* height: 250px; */
    /* border: 2px solid black; */
    background-image: linear-gradient(
      to right, transparent, var(--ion-color-light) 
      ), url("../../../public/assets/images/background.png");
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.aboutus-imp-wrpr {
    height: 100%;
    max-width: 30%;
    /* border: 3px solid purple; */
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
}

.aboutus-imp-wrpr-mob {
      display: flex;
      justify-content: center;
      margin-top: 10px;
}

.aboutus-img-mob {
    width: 50%;
}

.aboutus-img {
    width: 80%;
}
.about-us-font {
    font-size: 20px;
}
.aboutus-text {
    padding: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.about-us-para {
    margin: 10px;
}