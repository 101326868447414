.izp-overview {
  padding: 2em;
  text-align: center;
}
.izp-title {
  font-size: 3em;
  font-weight: bold;
  color: var(--ion-color-banner-navy-blue);
}
.izp-title-mob {
  font-size: 2.5em;
  font-weight: bold;
  color: var(--ion-color-banner-navy-blue);
}
.izp-text {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}
.izp-text-mob {
  font-size: 1.5em;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.izp-subtext {
  font-size: 1.3em;
  /* font-weight: bold; */
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  text-align: center;
}
.izp-subtext-mob {
  font-size: 1em;
  /* font-weight: bold; */
  text-align: center;
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}
.izp-parent-orientation {
  padding: 1em;
  font-size: 1.2em;
  text-align: center;
}
.izp-parent-orientation-title {
  padding: 0.5em;
  font-weight: bold;
  font-size: 1.5em;
  color: var(--ion-color-banner-navy-blue);
}
.subTitleTransforming {
  color: var(--ion-color-secondary);
  font-weight: bold;
  font-size: 2.5rem;
}

@media screen and (max-width: 880px) {
  .subTitleTransforming {
    color: var(--ion-color-secondary);
    font-weight: bold;
    font-size: 1.8rem;
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .subTitleTransforming {
    color: var(--ion-color-secondary);
    font-weight: bold;
    font-size: 1.4rem;
    display: block;
  }
}

@media screen and (max-width: 880px) {
  .overview_dash {
    display: none;
  }
}
