.pasteventbanner {
  height: 50vh;
  width: 100vw;
  display: flex;
}
.pasteventbanner_right {
  height: 100%;
  width: 40%;
  background-image: url("../../../public/assets/images/b1.png");
  background-size: cover;
}
.pasteventbanner_left {
  height: 100%;
  width: 60%;
  background-color: #faf8f7;
  color: var(--ion-color-banner-navy-blue);
}
.pasteventbanner_left_text {
  margin-left: 5rem;
}

.Typewriter__wrapper {
  font-size: 36px;
  color: black;
}

.pasteventbanner_left h1 {
  font-size: 5rem;
}
.pasteventbanner_left h2 {
  font-family: Water Brush, cursive;
  font-size: 4rem;
  color: black;
}
.pasteventbanner_left p {
  font-size: 2rem;
  color: black;
}

.redtext {
  color: #dd2121;
}

@media (max-width: 867px) {
  .pasteventbanner {
    height: 55vh;
  }
  .pasteventbanner_left h1 {
    font-size: 4rem;
  }
  .pasteventbanner_left h2 {
    font-size: 3rem;
  }
  .pasteventbanner_left p {
    font-size: 2rem;
  }
}
@media (max-width: 700px) {
  .pasteventbanner {
    flex-direction: column;
    height: 85vh;
  }
  .pasteventbanner_right {
    width: 100%;
    height: 60%;
  }
  .pasteventbanner_left {
    width: 100%;
    height: 40%;
  }
  .pasteventbanner_left h1 {
    font-size: 3rem;
  }
  .pasteventbanner_left h2 {
    font-size: 2rem;
  }
  .pasteventbanner_left p {
    font-size: 2rem;
  }

  .pasteventbanner_left_text {
    margin-left: 1rem;
  }
}
@media (max-width: 479px) {
  .pasteventbanner {
    flex-direction: column;
    height: 70vh;
  }
  .pasteventbanner_right {
    height: 40%;
  }
  .pasteventbanner_left {
    height: 60%;
  }
  .pasteventbanner_left h1 {
    font-size: 3rem;
  }
  .pasteventbanner_left h2 {
    font-size: 2rem;
  }
  .pasteventbanner_left p {
    font-size: 2rem;
  }
}

@media (max-width: 345px) {
  .pasteventbanner {
    flex-direction: column;
    height: 77vh;
  }
}
