.feature-card-header {
    font-size: 20px;
    font-weight: bold;
    color: var(--ion-color-secondary);
}
.feature-card-top {
    display: flex;
    justify-content: space-between;
    /* padding: 30px;
    padding-left: 10px; */
    /* background: url('../../../public/assets/images/background.png'); */
    /* background-image: linear-gradient(
      to left, transparent, var(--ion-color-light) 
      ), url("../../../public/assets/images/background.png"); */
    width: 100%;
    padding-left: 20px;
    /* border: 5px solid green; */
    height: 500px;
}
.feature-card-top-mob {
    display: flex;
    flex-direction: column;
}
.feature-card-img-wrpr {
    height: 100%;
    /* background: rgba(34, 23, 45, 1); */
    /* background: url('../../../public/assets/images/background.png'); */
    position: relative;
    margin-right: 50px;
    display: flex;
    justify-content: end;
    /* border: 5px solid red; */
    width: 40%;
}
.feature-card-img-wrpr-mob {
    height: 400px;
    width: 100%;
    display: flex;
    justify-content: center;
    /* background: rgba(34, 23, 45, 1); */
    /* background: url('../../../public/assets/images/background.png'); */
    /* position: relative; */
    /* margin-right: 50px; */
    /* border: 5px solid red; */
    /* width: 40%; */
}
.feature-card-img-mob {
    height: 100%;
    /* width: 100%; */
    /* object-fit: scale-down; */
    /* box-shadow: 10px 10px 11px 0px rgba(var(--ion-color-medium-rgb),0.62); */
}

.feature-card-img{
    height: 100%;
    overflow: hidden;
    object-fit: scale-down;
    /* box-shadow: 10px 10px 11px 0px rgba(var(--ion-color-medium-rgb),0.62); */
}
.feature-card-step-card {
    padding: 10px;
    border-bottom: 1px solid var(--ion-color-secondary);
}
.feature-card-step-header {
    font-weight: bold;
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 20px;
}
.feature-card-text {
    flex-grow: 1;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /* border: 5px solid black; */
}
.feature-card-steps {
    margin-bottom: 20px;
}
.feature-card-header-text {
    margin-top: 20px;
    font-size: 30px;
}

.feature-card-header-text-mob {
    margin-top: 20px;
    font-size: 20px;
}

.feature-card-steps-top-header {
    font-size: 20px;
    font-weight: bold;
    color: var(--ion-color-primary);
    margin-bottom: 20px;
}
.feature-card-steps-top-header-mob {
    font-size: 20px;
    /* font-weight: bold; */
    color: var(--ion-color-primary);
    margin-bottom: 10px;
    margin-top: 20px;
}