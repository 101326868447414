.submit-form-top {
    background-image: linear-gradient(
      to bottom, transparent, var(--ion-color-light) 
      ), url("../../../public/assets/images/background.png");
    display: flex;
    flex-direction: column;
    justify-content: space-around; 
    height: 100%;
}

.submit-form-img-wrpr {
    padding-top: 10px;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 3px solid black; */
    flex-shrink: 1;
}
.submit-form-img {
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.submit-form-header {
    /* height: 50px;
    background-color: var(--ion-color-medium);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: large;
    /* font-weight: bold; */
    /* color: var(--ion-color-light);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px; */ 
    border-top: 1px solid var(--ion-color-medium);
}
.submit-form {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}
.submit-form-clubber {
    display: flex;
    width: 100%;
}
.submit-form-clubber-el {
    width: 50%;
}