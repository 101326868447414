.stakeholder-card-top {
    display: flex;
    justify-content: space-between;
    background-color: var(--ion-color-grey-bg);
}
.stakeholder-card-img-wrpr, .stakeholder-card-text {
    width: 50%;
}

.stakeholder-card-text {
    padding: 20px;
    color: var(--ion-color-dark);
}

.stakeholder-card-text-title-tab {
    font-size: 3.5vw;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}

.stakeholder-card-text-title-mob {
    font-size: 4.5vw;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}

.stakeholder-card-text-title {
    font-size: 1.5vw;
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 20px;
}

.stakeholder-card-text-para-el {
    font-size: 1.2vw;
}

.stakeholder-card-text-para-el-tab {
    font-size: 2.2vw;
    line-height: 3vw;
}

.stakeholder-card-text-para-el-mob {
    font-size: 3.2vw;
    line-height: 4vw;
}