.collage img {
  width: 100%;
  object-fit: cover;
}
.container {
  max-width: 100vw;
  margin: 0px auto 0px auto;
  padding: 0px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.callage_text {
  font-size: 20px;
  max-width: 600px;
}

.blue_text {
  color: var(--ion-color-banner-navy-blue);
  font-weight: bold;
}

.collage-container {
  width: 600px;
  height: 500px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#collage-container {
  height: 270px;
}
.collage-container .collage {
  position: relative;
  height: 100%;
  width: 100%;
}
.collage-container .collage .collage-image1 {
  position: absolute;
  height: 70%;
  width: 50%;
  -webkit-clip-path: polygon(0px 30px, 30px 0px, 100% 0%, 100% 100%, 0% 100%);
  clip-path: polygon(0px 30px, 30px 0px, 100% 0%, 100% 100%, 0% 100%);
}
.collage-container .collage .collage-image2 {
  position: absolute;
  right: 0px;
  top: 50px;
  height: 250px;
  width: 60%;
  -webkit-clip-path: polygon(
    0 0,
    calc(100% - 30px) 0%,
    100% 30px,
    100% 100%,
    0 100%
  );
  clip-path: polygon(0 0, calc(100% - 30px) 0%, 100% 30px, 100% 100%, 0 100%);
}
.collage-container .collage .collage-image3 {
  position: absolute;
  right: 25px;
  bottom: 0px;
  height: 250px;
  width: 80%;
  -webkit-clip-path: polygon(
    0 0,
    100% 0%,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    0 100%
  );
  clip-path: polygon(
    0 0,
    100% 0%,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    0 100%
  );
}

@media (min-width: 1200px) and (max-width: 1441px) and (max-height: 850px) {
  .container {
    max-width: 100%;
    padding: 0px 36px;
  }
  .text {
    max-width: 400px;
  }
}
@media (max-width: 767px) {
  .container {
    padding: 0px 30px;
  }
  .text {
    max-width: 400px;
  }
}
@media (max-width: 479px) {
  .container {
    padding: 0px 20px;
  }
}

@media (max-width: 479px) {
  .image {
    flex: 1 0 49%;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
  }
}
@media (max-width: 767px) {
  .text {
    text-align: left;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .collage-container {
    width: 450px;
  }
}
@media (max-width: 570px) {
  .collage-container {
    width: 100%;
  }

  #collage-container {
    height: 500px;
  }
}
