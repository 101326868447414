.features-body {
    max-height: 500px;
    display: flex;
    /* border: 2px solid var(--ion-color-medium); */
}
.features-bg {    
    background-image: linear-gradient(
      to left, transparent, var(--ion-color-light) 
      ), url("../../../public/assets/images/background.png");
}

.features-navbar {
    width: 20%;
    /* border: solid 2px purple; */
    /* height: 100%; */
    height: 500px;
    /* border-right: 1px solid var(--ion-color-medium); */
}
.features-nav-list-common {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    padding: 5px;
    text-align: center;
}

.features-nav-list-common:hover {
    font-size: 17px;
    opacity: 0.8;
}

.features-nav-list-common-selected {
    /* border-top: 1px solid var(--ion-color-medium); */
    /* border-bottom: 1px solid var(--ion-color-medium); */
    /* border-radius: 10px; */
    font-size: 17px;
    color: var(--ion-color-primary-dark);
}

.features-nav-list-common-not-selected {
    /* border-right: 1px solid var(--ion-color-medium); */
    /* border-top: 1px solid var(--ion-color-medium); */
    /* border-bottom: 1px solid var(--ion-color-medium); */
    color: var(--ion-color-primary-lighter);
    background: var(--ion-color-primary-d1);
    font-weight: normal;
}

.features-nav-list-selected-first {
    border-top: none;
    background: linear-gradient(to bottom, var(--ion-color-light), var(--ion-color-primary-dark));
}
.features-nav-list-not-selected-last {
    border-bottom: none;
    background: linear-gradient(to bottom, var(--ion-color-primary-dark), var(--ion-color-light));
}
.feature-button {
    padding: 20px;
    display: flex;
    justify-content: center;
    padding-bottom: 35px;
}
.features-card-show-wrpr {
    width: 80%;
}
.feature-top {
    background-color: var(--ion-color-light);
    position: relative;
}
.features-card-show-wrpr-tab {
    padding-top: 10px;
    padding-bottom: 30px;
    border-bottom: 2px solid var(--ion-color-medium);
}
.features-card-show-wrpr-tab-bottom-pad {
    padding-bottom: 0;
}