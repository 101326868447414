.erp-overview-img-top {
    /* width: 35%; */
    height: 100%;
    /* padding-left: 50px; */
    width: 33%;
    /* flex-grow: 1; */
    /* border: 3px solid black; */
    display: flex;
    align-items: center;
}
.erp-overview-img-top-tab {
    /* width: 35%; */
    height: 100%;
    /* padding-left: 50px; */
    width: 40%;
    /* flex-grow: 1; */
    /* border: 3px solid black; */
    display: flex;
    align-items: center;
    margin-left: 10px;
    flex-grow: 1;
}

.erp-overview-img {
    height: 100%;
    width: 100%;
    /* border: 3px solid red; */
    position: relative;
    object-fit: fill;
}
.erp-overview-top {
    /* padding: 50px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: url('../../../public/assets/images/background.png');
    /* border: 2px solid black; */
    /* height: 500px; */
    height: 55vh;
    /* border: 3px solid purple; */
}
.erp-overview-text {
    /* font-size: 60px; */
    font-size: 3.5vw;
    font-weight: 600;
    color: var(--ion-color-dark);
    line-height: 4.5vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 20px;
    /* border: 1px solid black; */
    /* width: 33%; */
    /* flex-grow: 1; */
    /* border: 3px solid black; */
}
.erp-overview-subtext {
    font-size: 1vw;
    line-height: 1.5vw;
    font-weight: normal;
}
.erp-overview-subtext-tab {
    font-size: 2.5vw;
    line-height: 4.5vw;
    font-weight: normal;
    color: var(--ion-color-dark);
}
.erp-overview-kids-img-top {
    height: 100%;
    width: 33%;
    /* flex-grow: 1; */
    /* border: 3px solid black; */
}
.erp-overview-kids-img-top-tab {
    height: 100%;
    width: 40%;
    flex-grow: 1;
    /* flex-grow: 1; */
    /* border: 3px solid black; */
}
.erp-overview-tab-imgs {
    display: flex;
    justify-content: space-between;
    background: url('../../../public/assets/images/background.png');
    height: 50vh;
    position: relative;
}
.erp-overview-text-tab-hdr {
    font-size: 4.5vw;
    font-weight: 600;
    color: var(--ion-color-dark);
    line-height: 5.5vw;
    display: flex;
    box-shadow: 0px 9px 5px 0px rgba(var(--ion-color-medium-rgb),0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    padding: 15px;
    /* background: linear-gradient(to bottom, var(--ion-color-light), var(--ion-color-primary-dark)); */
    background: linear-gradient(to bottom, var(--ion-color-primary-light), var(--ion-color-light));
    color: var(--ion-color-primary-dark);

}
.erp-overview-text-mob-hdr {
    font-size: 6vw;
    font-weight: 600;
    color: var(--ion-color-dark);
    line-height: 7.5vw;
    display: flex;
    box-shadow: 0px 9px 5px 0px rgba(var(--ion-color-medium-rgb),0.75);
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
    padding: 15px;
    /* background: linear-gradient(to bottom, var(--ion-color-light), var(--ion-color-primary-dark)); */
    background: linear-gradient(to bottom, var(--ion-color-primary-light), var(--ion-color-light));
    color: var(--ion-color-primary-dark);
}
.erp-overview-subtext-mob {
    font-size: 3.5vw;
    line-height: 5vw;
    font-weight: normal;
    margin-top: 6px;
    color: var(--ion-color-dark);
}
.erp-overview-img-top-mob {
  width: 100%;
}
.erp-overview-mob-img-wrpr {
    background: url('../../../public/assets/images/background.png');
    position: relative;
}
.erp-overview-mob-get-started {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 15;
}