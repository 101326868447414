.footer_container {
  height: 34vh;
  width: 100vw;
  /* background: linear-gradient(to top, var(--ion-color-primary-light), var(--ion-color-light));
    color: var(--ion-color-primary-dark);  display: flex; */
  /* background-color:hsl(199, 100%, 11%); */
  background-color: var(--ion-color-primary-dark);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Jost", sans-serif;
}

.footer_container a {
  color: white;
}
.footer_container_main {
  height: 90%;
  width: 87%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footer_container_main h1 {
  text-align: left;
  /* border-left: 10px solid var(--ion-color-secondary); */
  display: block;
  font-size: 1.3em;
  font-weight: bold;
  padding-right: 0.5em;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-bottom: 1em;
  color: var(--ion-color-tertiary-light);
}
.footer_container_left {
  height: 99%;
  width: 30%;
}
.footer_container_middle {
  height: 99%;
  width: 30%;
}

.footer_container_right {
  height: 99%;
  width: 30%;
}
.footer_container_right p {
  line-height: 7px;
}
.footer_container_left li {
  padding-bottom: 17px;
}

.social_links {
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer-icons {
  padding-right: 20px;
}

.copyright {
  background-color: #011117;
  color: white;
  text-align: center;
  padding: 4px;
}

@media (max-width: 1200px) {
  .footer_container_middle {
    width: 20%;
  }
  .footer_container_right {
    width: 40%;
  }
  .footer_container_left {
    width: 40%;
  }
}

@media (max-width: 870px) {
  .footer_container_middle {
    width: 0%;
  }
  .footer_container_right {
    width: 50%;
  }
  .footer_container_left {
    width: 50%;
  }
}

@media (max-width: 680px) {
  .footer_container {
    height: 70vh;
  }
  .footer_container_main {
    flex-direction: column;
    height: 90%;
    width: 100%;
  }

  .footer_container_left {
    height: 50%;
    width: 90%;
  }
  .footer_container_middle {
    height: 0%;
  }
  .footer_container_right {
    height: 50%;
    width: 90%;
  }
}
