.swiper-slide {
  height: auto !important;
  text-align: center;
  border-radius: 8px;
  padding: 30px;
  margin: 2px;
  background-color: rgba(var(--ion-color-light-rgb), 0.7);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.snip1390 {
  font-family: "Roboto", Arial, sans-serif;
  color: var(--ion-color-primary-dark);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.snip1390 *,
.snip1390 *:before,
.snip1390 *:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.35s ease;
  transition: all 0.35s ease;
}

.snip1390 h2,
.snip1390 h4 {
  margin: 0;
}
.snip1390 h2,
.snip1390 h4 {
  font-weight: 300;
  text-align: center;
}
.snip1390 h2 {
  color: #000000;
}
.snip1390 h4 {
  color: #605656;
}
.blockquote {
  font-size: 1em;
  padding: 25px;
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: inset -1.4px -1.4px 2px rgba(0, 0, 0, 0.3);
  text-align: left;
  position: relative;
  width: 99%;
  height: 100%;
  display: flex;
  flex-grow: 1;
}
.blockquote_box {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
}
a {
  text-decoration: none;
}
/* .blockquote:before {
  font-family: "FontAwesome";
  content: "\201C";
  position: absolute;
  font-size: 70px;
  opacity: 0.25;
  font-style: normal;
  top: 0px;
  left: 20px;
} */
.snip1390 .profile {
  width: 100px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
  border: 4px solid var(--ion-color-secondary);
}
.card-col {
  background-color: rgba(var(--ion-color-light-rgb), 0.7);
}
