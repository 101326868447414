.pricing-bg {
    /* background: linear-gradient(to bottom right, var(--ion-color-light), var(--ion-color-sky)); */
    background: var(--ion-color-light);
}
.pricing-top {
    height: 500px;
    display: flex;
    justify-content: space-between;
}
.pricing-top-mob {
    /* height: 500px; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.pricing-img-wrp {
    height: 100%;
    /* border: 5px solid black; */
    background: linear-gradient(to right, var(--ion-color-light), var(--ion-color-sky));
}
.pricing-img-wrp-mob {
    width: 100%;
    /* border: 5px solid black; */
    background: linear-gradient(to bottom, var(--ion-color-light), var(--ion-color-sky));
}
.pricing-img {
    height: 100%;
    object-fit: cover;
    /* border: 5px solid red; */
}

.pricing-img-mob {
    height: 100%;
    width: 100%;
    object-fit: cover;
    /* border: 5px solid red; */
}
.pricing-text {
    font-size: 30px;
    padding-left: 50px;
    font-weight: bold;
    line-height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* border: 3px solid black; */
    flex-grow: 1;
    align-items: center;
    background-image: linear-gradient(
      to right, transparent, var(--ion-color-light) 
      ), url("../../../public/assets/images/background.png");
    color: var(--ion-color-primary-dark);  
}
.pricing-feature-card-title {
    margin-top: 50px;
    font-size: 35px;
    /* border: 2px solid black; */
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: var(--ion-color-secondary);
}

.pricing-feature-card-title-mob {
    font-size: 5vw;
    color: var(--ion-color-primary);
    font-weight: normal;
    text-align: start;
}

.pricing-feature-list-hdr {
    font-size: 20px;
    font-weight: bold;
    color: var(--ion-color-secondary);
    margin-bottom: 20px;
    margin-top: 20px;
}
.pricing-feature-el {
    padding: 10px;
    border-bottom: 1px solid var(--ion-color-secondary);
    /* font-weight: bold; */
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 20px;
}

.pricing-feature-el-mob {
    padding: 10px;
    text-align: start;
    border-bottom: 1px solid var(--ion-color-secondary);
    /* font-weight: bold; */
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 5vw;
    font-weight: normal;
    color: var(--ion-color-dark);
}

.pricing-features {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}

.pricing-features-mob {
    display: flex;
    flex-direction: column;
    /* padding-right: 20px; */
}
.pricing-feature-card-body {
    /* border: 2px solid black; */
    flex-grow: 1;
    display: flex;
    justify-content: end;
    flex-direction: column;
}
.pricing-button {
    padding-top: 20px;
    padding-bottom: 35px;
    display: flex;
    justify-content: center;
}
.pricing-button-mob {
    padding-top: 5px;
    padding-bottom: 15px;
    display: flex;
    justify-content: center;
    background: var(--ion-color-sky);
}
.pricing-top-wrpr {
    position: relative;
}

.pricing-text-mob {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    text-align: start;
    background-image: linear-gradient(
      to bottom, transparent, var(--ion-color-light) 
      ), url("../../../public/assets/images/background.png");
    color: var(--ion-color-secondary);  
    padding-left: 20px;
}