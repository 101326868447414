@import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap");

.feature_card_col {
  display: flex;
  flex-grow: 1;
}

.feature_card_container {
  display: flex;
  background-color: rgba(250, 183, 59, 0.1);
  /* margin-top: 24vh; */
}

.Feature_cards_title {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-family: '" Vollkorn", serif';
}

.Feature_cards_title h1 {
  font-size: 2.2rem;
  color: #353935;
  font-weight: bold;
}

.Feature_cards_title p {
  font-size: 1.1rem;
  letter-spacing: 2px;
  color: #353935;
}

.feature_card {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 30px 10px 0px 10px;
  border-radius: 12px;
}

.feature_card_img {
  height: 100px;
  width: 100px;
}

.feature_card_p {
  font-size: 1.1rem;
  line-height: 20px;
  text-align: center;
  font-style: normal;
}

.feature_card_p_box {
  /* padding: 25px 0px 0px 0px; */
}

.feature_card_p_h1 {
  font-weight: bold;
  text-align: center;
  font-style: normal;
}

.feature_card_row {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}

@media screen and (max-width: 1100px) {
  .feature_card_container {
    /* margin-top: 26vh; */
  }
}

@media screen and (max-width: 900px) {
  .feature_card_container {
    /* margin-top: 25vh; */
  }

  .feature_card_p {
    font-size: 1rem;
    margin: 0px;
  }
}

@media screen and (max-width: 600px) {
  .feature_card_row {
    flex-direction: column;
  }

  .feature_card_container {
    /* margin-top: 22vh; */
  }

  .Feature_cards_title h1 {
    font-size: 1.6rem;
  }

  .Feature_cards_title p {
    font-size: 1rem;
    letter-spacing: 1px;
  }
}