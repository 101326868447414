.landing_listing-heading {
  background: var(--ion-color-light);
  font-size: 1.5rem;
  color: gray;
  font-weight: bold;
  z-index: 100;
  padding: 20px 0px 0px 0px;
  text-align: center;
  /* border: 1px solid black; */
}
.landing_listing_container {
  text-transform: capitalize;
  position: relative;
  background-image: linear-gradient(
    to bottom,
    var(--ion-color-light),
    #fab73b1a
  );
  /* border: 1px solid purple; */
  height: 35vh;
}
.landing_listing_box {
  animation: primary 120s linear infinite;
  /* background: var(--ion-color-light); */
  background: none;
  padding: 10px;
  display: flex;
  overflow-x: auto;
  position: absolute;
  left: 0%;
  top: 0%;
}
.landing_listing_box:hover {
  animation-play-state: paused;
}
.landing_listing_img {
  flex-shrink: 0;
  margin: 20px;
  border-radius: 50%;
  height: 120px;
  width: 120px;
}
.landing_listing_img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}
.landing_listing_box::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.landing_listing_name {
  text-align: center;
}
@keyframes primary {
  0% {
    left: 0%;
  }
  50% {
    left: -200%;
  }
  100% {
    left: 0%;
  }
}

@media screen and (max-width: 900px) {
  .landing_listing_img {
    height: 100px;
    width: 100px;
  }
}
@media screen and (max-width: 600px) {
  .landing_listing_img {
    height: 80px;
    width: 80px;
  }
  .landing_listing_container {
    height: 25vh;
  }
}
